@import '../global/library.scss';
body {
    margin: 0;
    padding: 0;
    background-color: white;
    font-family: $primary-font;
    overflow-x: hidden;
    overscroll-behavior: none;
    h1 {
        @include header-large-bold;
    }
    .header_wrapper {
        padding-top: .25rem;
        padding-bottom: .25rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 10vw;
        z-index: 1;
        color: white;
        background-image: $blue-gradient;
        margin-bottom: 6rem;
        margin-top: 0;
        padding-top: 0;
        position: sticky;
        z-index: 10;
        @media (max-width: 767px) {
            margin-bottom: 3rem;
        }
        h1 {
            color: white;
            text-shadow: 2px 3px rgba(20, 20, 20, .7);
            text-transform: uppercase;
            font-size: 36px;
            line-height: 40px;
            @media (max-width: 767px) {
                font-size: 32px;
            }
        }
    }
    input[type="text"],
    input[type="password"],
    select {
        @include input-field;
        background-color: white;
    }
    input[type="submit"],
    button {
        @include blue-btn;
    }
    p {
        @include body-text;
    }
    table {
        .present_data {
            text-align: center;
            padding-left: 0;
        }
        .present_header {
            text-align: center;
        }
        .id_number {
            text-align: right;
        }
        .id_data {
            text-align: right;
        }
        .delete {
            text-align: center;
        }
    }
}

@media (max-width: 767px) {
    body {
        .header_wrapper {
            padding-left: 0;
            padding-left: 24px;
        }
    }
}