@import "../../global/library.scss";
.attendance_table_wrapper {
    h3 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    h2 {
        margin-bottom: 0px;
    }
    p {
        margin-top: 0px;
        margin-bottom: 20px;
        font-size: 14px;
        text-transform: uppercase;
    }
    @include main-table-wrapper;
    border-radius: 16px;
    padding-bottom: 2rem;
    margin-top: 2rem;
    .btn_group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-right: 1rem;
    }
    table {
        @include full-width-table;
        th {
            @include table-headers;
        }
        .first_name,
        .last_name,
        .name {
            text-align: left;
            padding-left: 1rem;
        }
        .dark_row {
            background-color: $blue-highlight;
        }
        .number_id {
            text-align: right;
        }
        .delete,
        .delete_button {
            text-align: center;
        }
        td {
            @include table-center-data;
            text-align: left;
            padding-left: 1rem;
            button {
                width: auto;
                padding: 6px 12px;
                border-radius: 50%;
            }
            .present_click {
                @include icon-button;
            }
            .check {
                font-size: 32px;
                font-weight: 200;
            }
            .circle {
                font-size: 16px;
            }
            .trash_can {
                @include trash-icon;
            }
            .trash_btn {
                background-color: transparent;
                &:hover {
                    box-shadow: none;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .attendance_table_wrapper {
        table {
            td {
                width: 25%;
            }
        }
    }
}