@import "../../global/library.scss";
#line_graph {
    @include shadow-form;
    margin-bottom: 6rem;
    margin-top: 0;
    padding-top: 0;
    overflow-x: default;
    @media (max-width: 767px) {
        overflow-x: scroll;
    }
    h2 {
        margin-top: 1rem;
        margin-bottom: 0;
    }
    h3 {
        margin-top: 0;
    }
    .recharts-default-legend {
        @media (max-width: 767px) {
            display: flex;
            flex-wrap: wrap;
            width: 80%;
            column-gap: 20px;
            row-gap: 20px;
            justify-content: center;
        }
    }
    .recharts-legend-wrapper {
        @media (max-width: 767px) {
            left: 40px;
            display: flex;
            justify-content: center;
        }
    }
}