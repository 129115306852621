@import "../../global/library.scss";
#login_form {
    @include shadow-form;
    .input_pair {
        @include shadow-form-input-pair;
        label {
            display: none;
        }
    }
    .submit_btn {
        @include blue-btn;
        margin: 1rem auto 0 auto;
    }
}