@import "../../global/library.scss";
#all_people_table_wrapper {
    @include main-table-wrapper;
    margin-bottom: 4rem;
    padding-bottom: 2rem;
    min-width: 250px;
    margin-top: 2rem;
    table {
        @include full-width-table;
        th {
            @include table-headers;
            text-align: left;
            padding-left: 12px;
            padding-right: 12px;
        }
        tr {
            &:hover {
                background-color: $blue-highlight;
            }
        }
        td {
            @include table-center-data;
            text-align: left;
            padding-left: 12px;
            padding-right: 12px;
        }
        button {
            @include icon-button;
        }
        .user_check,
        .user_active {
            font-size: 20px;
            color: $dark-blue;
        }
        .trash_can {
            @include trash-icon;
        }
        .align_center {
            text-align: center;
        }
        .member_status_header,
        .edit_header,
        .delete_header {
            text-align: center;
        }
    }
}

@media (min-width: 1024px) {
    #all_people_table_wrapper {
        width: 960px;
    }
}

@media (max-width: 1024px) {
    #all_people_table_wrapper {
        width: 95%;
    }
}

@media (max-width: 767px) {
    #all_people_table_wrapper {
        table {
            th {
                font-size: 14px;
                padding-left: 10px;
                padding-right: 10px;
            }
            td {
                font-size: 14px;
            }
        }
    }
}