@import "../../global/library.scss";
#all_data_form {
    @include shadow-form;
    padding-top: 32px;
    @media (max-width: 767px) {
        margin-bottom: 4rem;
    }
    h2 {
        margin-top: 0;
    }
    .year_month_options {
        width: 60%;
    }
    .submit_button {
        margin-top: 1rem;
    }
}