@import "../../global/library.scss";
.add_new_member_btn {
    @include new-member-btn;
    .add_new_member_btn_text {
        @include accent-bold;
        color: white;
        font-size: 14px;
        line-height: 18px;
        @media(max-width: 767px) {
            font-size: 12px;
            line-height: 16px;
        }
    }
    .add_member_icon {
        font-size: 22px;
        @media(max-width: 767px) {
            font-size: 18px;
        }
    }
}

.single_btn {
    @include new-member-btn;
    min-width: 250px;
}