@import "../../global/library.scss";
#search_form_wrapper {
    width: 100%;
    margin-left: 1rem;
    margin-bottom: 0;
    #inner_search_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        @include input-field;
        width: 50%;
        max-width: 350px;
        input {
            width: 100%;
            box-shadow: none;
            border: none;
        }
        .search {
            color: $dark-blue;
        }
    }
}

@media (max-width: 767px) {
    #search_form_wrapper {
        #inner_search_wrapper {
            width: 85%;
        }
    }
}