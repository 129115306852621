@import "../../global/library.scss";
#new_group_wrapper,
#attendance_title_wrapper {
    @include inner-shadow-form-wrapper;
    .input_wrapper,
    .input_pair {
        @include shadow-form-input-pair;
        label {
            margin-bottom: 0.5rem;
        }
    }
}

#new_group_wrapper .two_col_input_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    column-gap: 2rem;
    align-items: center;
    .inner_input_wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        input {
            width: 20px;
            height: 15px;
            margin-left: 0px;
        }
        label {
            margin-bottom: -.15rem;
        }
    }
}