@import "../global/library.scss";
#attendance_wrapper {
    padding-bottom: 8rem;
    #attendance_content_wrapper {
        @include content-wrapper-md;
        .button_group {
            display: flex;
            flex-direction: row;
            row-gap: 2rem;
            column-gap: 2rem;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 2rem;
            @media(max-width: 679px) {
                column-gap: 1rem;
            }
        }
    }
}