@import "../../global/library.scss";
#new_or_existing_wrapper {
    @include shadow-form;
    padding-top: 2rem;
    .button_wrapper {
        display: flex;
        flex-direction: row;
        column-gap: 2rem;
        justify-content: center;
    }
}