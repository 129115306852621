@import "../../styles/global/library.scss";
#search_content_wrapper {
    @include content-wrapper-md;
    display: flex;
    flex-direction: column;
    padding-bottom: 8rem;
    #form_download_wrapper {
        @include shadow-form;
        margin-top: 0px;
        padding-top: 2rem;
        margin-bottom: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        form {
            @include shadow-form;
            box-shadow: unset;
            padding-top: 0px;
            padding-bottom: 0px;
            margin-top: 0px;
        }
    }
}

@media (max-width: 1024px) {
    #search_content_wrapper {
        padding-bottom: 6rem;
    }
}