@import "../../global/library.scss";
.full_height_popout {
    @include full-height-popout-background;
}

#new_attendant_form_wrapper {
    @include popout-content;
    .close_btn {
        @include icon-close-btn;
    }
    #new_member_form {
        @include inner-shadow-form-wrapper;
        .form_inner_wrapper {
            @include inner-shadow-form-wrapper;
            row-gap: 3rem;
            h3 {
                display: flex;
                align-self: flex-start;
                width: 60%;
                margin: auto auto -2rem auto;
                @media (max-width: 767px) {
                    width: 90%;
                }
            }
        }
        .fields_wrapper {
            @include inner-shadow-form-wrapper;
        }
        .input_wrapper {
            @include shadow-form-input-pair;
        }
        .age_fields_wrapper,
        .member_fields_wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            column-gap: 2rem;
            width: 60%;
            margin: auto;
            min-width: 250px;
            row-gap: 1rem;
            @media(max-width: 767px) {
                width: 90%;
            }
            .input_wrapper {
                display: flex;
                flex-direction: row;
                width: auto;
                min-width: unset;
                label {
                    margin-bottom: 0;
                }
                input {
                    width: 20px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    #new_attendant_form_wrapper {
        overflow-y: scroll;
        height: 80vh;
        padding-top: 2rem;
        margin-top: 10px;
    }
}