@import "../../global/library.scss";
.date_wrapper {
    @include shadow-form-input-pair;
    width: 40%;
    min-width: 40%;
    row-gap: 8px;
}

@media (max-width: 767px) {
    .date_wrapper {
        width: 90%;
        min-width: 250px;
    }
}