$dark-background: #293241;
$orange: #ee6c4d;
$dark-orange: #c75437;
$light-blue: #e0fbfc;
$medium-blue: #98c1d9;
$dark-blue: #3d5a80;
$purple: #86198f;
$primary-font: Overpass,
sans-serif;
$blue-highlight: rgba(152, 193, 217, .2);
$blue-gradient: linear-gradient(130deg, $dark-background, $dark-blue, $dark-background);
@mixin header-large-bold {
    font-family: $primary-font;
    line-height: 52px;
    color: black;
    font-weight: 900;
    font-size: 48px;
    text-transform: capitalize;
    text-shadow: 2px 3px rgba(240, 240, 240, .7);
}

@mixin accent-bold {
    font-family: $primary-font;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 24px;
    color: black;
    text-transform: uppercase;
}

@mixin body-text {
    font-family: $primary-font;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    color: black;
}

@mixin btn-text {
    font-family: $primary-font;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: black;
}

@mixin input-field {
    @include body-text;
    border: 1px solid rgba(41, 50, 65, .6);
    outline: none;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    &:focus-visible {
        border: 1px solid $orange;
        box-shadow: 0 2px 8px rgba(238, 108, 77, .8);
    }
}

@mixin blue-btn {
    @include btn-text;
    width: 150px;
    background-color: $dark-blue;
    color: white;
    padding-top: .75rem;
    padding-bottom: .75rem;
    border-radius: 5px;
    border: none;
    outline: none;
    &:hover {
        box-shadow: 0 2px 6px $dark-blue;
        cursor: pointer;
    }
    &:focus-visible {
        box-shadow: 0 2px 6px rgba(238, 108, 77, .8);
    }
}

@mixin new-member-btn {
    margin: auto;
    display: flex;
    flex-direction: row;
    column-gap: .75rem;
    justify-content: center;
    align-items: center;
    align-content: bottom;
    min-width: 200px;
    height: 64px;
    padding: 12px 36px;
    @media(max-width: 767px) {
        min-width: 200px;
    }
}

@mixin content-wrapper-md {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    align-self: center;
    height: 100%;
    max-width: 750px;
    min-width: 300px;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    @media (max-width: 767px) {
        padding-left: .5rem;
        padding-right: .5rem;
    }
}

@mixin shadow-form {
    box-shadow: 0 16px 32px rgba(0, 0, 0, .3);
    border-radius: 16px;
    padding-top: 6rem;
    padding-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    margin-top: 2rem;
}

@mixin inner-shadow-form-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
    justify-content: center;
    align-items: center;
}

@mixin shadow-form-input-pair {
    display: flex;
    flex-direction: column;
    width: 60%;
    min-width: 250px;
    @media (max-width: 767px) {
        width: 90%;
    }
}

@mixin main-table-wrapper {
    @include shadow-form;
    max-width: 1400px;
    min-width: 300px;
    padding-bottom: 0;
    padding-top: 1rem;
    row-gap: .5rem;
    margin-top: 4rem;
}

@mixin full-width-table {
    width: 100%;
    border-collapse: collapse;
}

@mixin table-headers {
    padding-top: 18px;
    padding-bottom: 18px;
    background-color: $dark-blue;
    @include accent-bold;
    color: white;
}

@mixin table-center-data {
    text-align: center;
    padding-bottom: 14px;
    padding-top: 14px;
}

@mixin trash-icon {
    padding: 0;
    color: $orange;
    font-size: 20px;
    transition: all .4s ease-in-out;
    &:hover {
        color: $dark-orange;
        transform: scale(1.25);
    }
}

@mixin icon-button {
    width: auto;
    background-color: transparent;
    color: $dark-blue;
    font-size: 20px;
    padding: 0;
    transition: all .4s ease-in-out;
    &:hover {
        box-shadow: none;
        color: $dark-background;
        transform: scale(1.25);
    }
}

@mixin icon-close-btn {
    @include icon-button;
    font-size: 36px;
    position: absolute;
    right: 2rem;
    top: 12px;
    &:hover {
        transform: unset;
    }
}

@mixin full-height-popout-background {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .8);
    z-index: 10;
}

@mixin popout-content {
    @include shadow-form;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 4rem;
    background-color: white;
    width: 60%;
    margin: auto;
    margin-top: 4rem;
    min-width: 350px;
}