@import "../../global/library.scss";
.alert_message_wrapper {
    position: fixed;
    top: 40px;
    right: 20px;
    max-width: 250px;
    background-color: $orange;
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: .25rem 2rem .25rem 2rem;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(41, 50, 65, .6);
    opacity: 0;
    transition: all .7s ease-in-out;
    z-index: 250;
    p {
        @include accent-bold;
        color: white;
        font-size: 16px;
    }
}