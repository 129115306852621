@import "../../global/library.scss";
#group_dropdown_wrapper {
    @include shadow-form;
    padding-top: 2rem;
    .inner_form_wrapper {
        @include inner-shadow-form-wrapper;
    }
    form {
        @include inner-shadow-form-wrapper;
    }
}