@import "../../global/library.scss";
#loading_page_container {
    @include full-height-popout-background;
    #loading_outer_container {
        @include popout-content;
        background-color: rgba(0, 0, 0, 0);
        box-shadow: none;
        margin-top: 40vh;
        #loading_outer_color {
            background-image: radial-gradient(farthest-corner at 0px 0px, white, $orange 70%);
            height: 150px;
            width: 150px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            animation-name: spin;
            animation-duration: 3.75s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            #loading_inner_color {
                background-color: rgba(0, 0, 0, .4);
                height: 120px;
                width: 120px;
                border-radius: 50%;
            }
        }
    }
    @keyframes spin {
        from {
            transform: rotate(-360deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}