@import "../../global/library.scss";
@import "../../global/library.scss";
.full_height_popout {
    @include full-height-popout-background;
}

#attendant_dropdown_wrapper {
    @include popout-content;
    .close_btn {
        @include icon-close-btn;
        z-index: 5;
    }
    #attendant_dropdown_box_wrapper {
        height: 80vh;
        overflow-y: scroll;
        @include inner-shadow-form-wrapper;
        row-gap: 1rem;
        justify-content: flex-start;
        form {
            @include inner-shadow-form-wrapper;
            select {
                width: 60%;
                @media(max-width: 767px) {
                    width: 90%;
                }
            }
        }
    }
}

#display_attendants_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 2rem;
    width: 60%;
    margin-top: 4rem;
    min-width: 250px;
    row-gap: 0rem;
    border: 1px solid rgba(0, 0, 0, .7);
    border-radius: 5px;
    height: 250px;
    overflow-y: scroll;
    @media(max-width: 767px) {
        width: 90%;
    }
    h3 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        background-color: $dark-blue;
        font-size: 16px;
        min-height: 72px;
        @include accent-bold;
        color: white;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        row-gap: 0;
        margin-top: -1rem;
        width: 100%;
        padding-left: 0 !important;
        .list_data {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding-top: 12px;
            padding-bottom: 12px;
            margin: auto;
            li {
                padding-left: 12px;
            }
            .trash_btn {
                @include icon-button;
                padding-right: 12px;
            }
            .trash_can {
                @include trash-icon;
            }
        }
        .list_data:nth-child(even) {
            background-color: $medium-blue;
        }
    }
}

@media (max-width: 767px) {
    #attendant_dropdown_wrapper {
        overflow-y: scroll;
        height: 80vh;
        padding-top: 2rem;
        margin-top: 10px;
    }
}