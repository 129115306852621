@import '../../global/library.scss';
#main_nav_wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $dark-background;
    z-index: 5;
    padding-top: 95px;
    ul {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        padding: 18px 18px;
        li {
            list-style: none;
            margin: auto;
            a {
                color: white;
                font-size: 20px;
                text-decoration: none;
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                justify-content: center;
                align-items: center;
                &:hover,
                &:hover p {
                    color: $orange;
                }
            }
            .active_page_highlight,
            .active_page_highlight p {
                color: $orange;
            }
            p {
                text-decoration-style: none;
                font-size: 12px;
                font-weight: 500;
                color: white;
                text-transform: uppercase;
                margin-top: 0px;
                margin-bottom: 0px;
                line-height: 1em;
                &:hover a,
                &:hover {
                    color: $orange;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    #main_nav_wrapper {
        position: fixed;
        left: 0;
        bottom: 0;
        top: unset;
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 0;
        ul {
            // display: flex;
            // flex-direction: row;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            column-gap: 20px;
            padding-bottom: 2rem;
            justify-content: center;
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }
}